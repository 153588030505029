<template>
  <div class="accordion">
    <button :class="{ 'name-btn': true, open: isActive }"
            @click="isActive = !isActive">
      <i :class="food.icon" />{{ food.name }}
    </button>
    <div :class="{ collapse: true, 'd-block': isActive }">
      <ul class="prop-items-list">
        <li class="attache"
            v-for="(product, index) in food.products"
            :key="index">
          <a href="javascript:;"
             @click="addProduct(product)"
             class="btn btn-sm btn-outline-primary">+ Add</a>
          <h6>{{ product.name }}</h6>
          <p>{{ product.unit }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    food: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    addProduct(product) {
      this.$emit('addProduct', product);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
