<template>
  <div>
    <MyHeader />
    <main id="content">
      <nav class="user-tab-nav">
        <div class="container">
          <ProfileMenu />
        </div>
      </nav>
      <div class="container mt-4 mt-lg-5">
        <BookingInfo />
        <div class="row mb-4 mb-lg-5">
          <div class="col-12">
            <UserTripMenu />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 col-lg-6 mb-4"
               v-if="booking && booking.status !== 3">
            <h3 class="min-title">
              Products Available
            </h3>
            <p>
              Choose the products that we have available and they will be at the moment of arriving at the property. If you can’t find a product you can <router-link :to="{ name: 'user-message', params: { id: booking.id }}">
                write your concierge
              </router-link> and he will assist you.
            </p>
            <FoodInventory v-for="(food, index) in foodInventory"
                           :key="index"
                           @addProduct="addProduct"
                           :food="food" />
            <p><small>*The brands will be available based on the location of the property</small></p>
          </div>
          <div class="col-12 col-lg-6">
            <div class="h-100 border p-3 p-lg-4 p-xl-5">
              <h3 class="min-title">
                My Grocery List
              </h3>
              <hr>
              <div class="sticky-scroll">
                <ul class="grocery-list"
                    v-if="list && list.length > 0">
                  <li v-for="(product, index) in list"
                      :key="index">
                    <div class="name">
                      {{ product.product.name }}
                      <small>{{ product.product.unit }}</small>
                    </div>
                    <div class="control">
                      <input type="text"
                             v-model="product.note"
                             @change="showSendButton = true; message = '';"
                             class="form-control-sm form-control"
                             placeholder="Specific brand">
                      <div class="quantity">
                        <div class="d-flex">
                          <button type="button"
                                  @click="product.quantity > 1 ? product.quantity-- : product.quantity; showSendButton = true; message = '';">
                            <i class="icon-remove" />
                          </button>
                          <input type="text"
                                 v-model="product.quantity"
                                 @change="showSendButton = true">
                          <button type="button"
                                  @click="product.quantity++; showSendButton = true; message = '';">
                            <i class="icon-plus" />
                          </button>
                        </div>
                        <a href="javascript:;"
                           class="remove"
                           @click="removeProduct(index)">Remove</a>
                      </div>
                    </div>
                  </li>
                </ul>

                <div class="row mt-5">
                  <div class="col col-md-auto pr-2"
                       v-if="list.length > 0">
                       <!-- <a href="javascript:;"
                       @click="printPage"
                       class="btn btn-sm btn-block btn-outline-primary"><i class="icon-print" /> PRINT</a> -->
                  </div>
                  <div class="col col-md-auto pr-2"
                       v-if="list.length > 0">
                    <a href="javascript:;"
                       @click="printPage"
                       class="btn btn-sm btn-block btn-outline-primary"><i class="icon-print" /> PRINT</a>
                  </div>
                  <div class="col col-md-auto pl-2">
                    <div class="alert alert-success"
                         v-if="message"
                         role="alert">
                      Your list has been saved!
                    </div>
                    <a href="javascript:;"
                       @click="saveProducts"
                       v-if="showSendButton"
                       class="btn btn-sm btn-block btn-outline-primary">SEND</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import UserTripMenu from '../../components/users/UserTripMenu.vue';
import FoodInventory from '../../components/users/FoodInventory.vue';
import BookingInfo from '../../components/users/BookingInfo.vue';

export default {
  name: 'UserMessageDetail',
  components: {
    ProfileMenu,
    UserTripMenu,
    FoodInventory,
    BookingInfo
  },
  data() {
    return {
      foodInventory: {},
      // booking: {},
      list: [],
      message: false,
      showSendButton: false,
    };
  },
  methods: {
    ...mapActions([
      'requestFoodInventory',
    ]),
    printPage() {
      window.print();
    },
    addProduct(product) {
      this.list.push({
        product,
        product_id: product.id,
        note: '',
        quantity: 1,
      });
      this.showSendButton = true;
      this.message = '';
    },
    removeProduct(index) {
      this.list.splice(index, 1);
      this.showSendButton = true;
      this.message = '';
    },
    saveProducts() {
      const formData = new FormData();
      formData.append('products', JSON.stringify(this.list));
      this.$axios.post(`/v1/bookings/${this.$route.params.id}/products`, formData).then((response) => {
        this.message = response.data.data;
        this.showSendButton = false;
        Promise.resolve();
      }).catch(error => Promise.reject(error));
    },
  },
  computed: {
    booking() {
      return this.$store.getters.getBookingTrip;
    }
  },
  created() {
    setTimeout(() => {
      const { status } = this.$store.getters.getBookingTrip;
      if (status === 2) {
        this.$router.push({ name: 'user-trip-overview', params: { id: this.$route.params.id } });
      } else if (!this.$route.params.id) {
        this.$router.push('/');
      }
    }, 500);
    // this.booking = JSON.parse(this.$localStorage.get('bookingTrip'));
    this.requestFoodInventory().then((response) => {
      this.foodInventory = response;
    });
    this.$axios.get(`/v1/bookings/${this.$route.params.id}/products`).then((response) => {
      this.list = response.data.data;
      Promise.resolve();
    }).catch(error => Promise.reject(error));
  },
};
</script>

<style lang="scss" scoped>

</style>
